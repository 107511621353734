import React, { useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "./Form";
import { useModal } from "@/hooks/modal.hook";
// import ThanksModal from "@/components/ThanksModal";
import { generateStars } from "@/utils/helpers/styles";

function GetTouch() {
  const { openModal, closeCurrentModal, currentModal } = useModal();

  const [isSuccess, setIsSuccess] = React.useState(false);

  const starContainerRef = useRef(null);

  useEffect(() => {
    if (starContainerRef.current) {
      const removeStars = generateStars(starContainerRef.current, 25);
      return () => {
        removeStars!();
      };
    }
  }, [starContainerRef]);

  return (
    <Row
      className='justify-content-center align-items-center GetTouch px-4 position-relative'
      id='ContactUs'
      ref={starContainerRef}
    >
      <Col md={10}>
        <Row className='align-items-center '>
          <Col lg={6}>
            <h2 className='text-gradient half-gradient fw-bold  mt-n-5'>Get In Touch</h2>
            <p>
              Whether you have a question on one of our services or want to get involved with our
              mission we are ready to help.
            </p>
            <h3 className='mt-5'>Company info</h3>
            <p>
              Metachain Technologies, Inc.
              <br />
              Address: 800 W Pender St. Suite 1140, Vancouver, BC, V6C 1J8.
              <a href='mailto:management@metachaintechnologies.com' className='break-word d-block'>
                management@metachaintechnologies.com
              </a>
            </p>
          </Col>
          <Col lg={6}>
            <Row className='justify-content-center align-items-center bg-border-gradiant contact-form-bg'>
              <Col lg={12} xl={12}>
                <Form
                  categoryId='5'
                  onSubmit={() => {
                    openModal("submit");
                    setIsSuccess(true);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GetTouch;
