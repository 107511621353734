import React from "react";

export const isCSR = () => typeof window !== "undefined";

export const twoDigitsNumber = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

export const findContext = (longText: string, shortText: string) => {
  const words = longText.split(" ");

  let startIndex = 0;
  let endIndex = words.length - 1;

  for (let i = 0; i < words.length; i++) {
    const subsequence = words.slice(i, i + shortText.split(" ").length).join(" ");
    if (subsequence.includes(shortText)) {
      startIndex = Math.max(0, i - 3);
      endIndex = Math.min(words.length - 1, i + shortText.split(" ").length + 2);
      break;
    }
  }

  return words.slice(startIndex, endIndex + 1).join(" ");
};

export const handleNavigationOutside = (link: string) => (e: React.MouseEvent) => {
  e.stopPropagation();
  window.open(link, "_blank");
};
