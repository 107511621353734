// contexts/modalContext.js
import React, { createContext, useContext, useState } from "react";

const CalendlyModalContext = createContext({
  isCalendlyOpen: false,
  toggleModal: () => {},
});

export const CalendlyModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const toggleModal = () => {
    setIsCalendlyOpen(!isCalendlyOpen);
  };

  return (
    <CalendlyModalContext.Provider value={{ isCalendlyOpen, toggleModal }}>
      {children}
    </CalendlyModalContext.Provider>
  );
};

export const useCalendlyModal = () => {
  const context = useContext(CalendlyModalContext);
  if (context === undefined) {
    throw new Error("useCalendlyModal must be used within a CalendlyModalProvider");
  }
  return context;
};

export default CalendlyModalContext;
