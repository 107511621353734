import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { socialMediaConfig as socialMedia } from "../../../configs/footer.config";
import Image from "next/image";
import FooterLogo from "@/assets/img/footerLogo.png";
import BigPuzzle from "@/assets/svg/puzzle.svg";
import SmallPuzzle from "@/assets/svg/puzzle-sm.svg";
import { useEffect, useRef } from "react";
import { generateStars } from "@/utils/helpers/styles";

function Footer() {
  const starContainerRef = useRef(null);

  useEffect(() => {
    if (starContainerRef.current) {
      const removeStars = generateStars(starContainerRef.current, 100);
      return () => {
        removeStars!();
      };
    }
  }, [starContainerRef]);
  return (
    <Row
      className='justify-content-center ali.currentcenter footer position-relative overflow-hidden-mob'
      ref={starContainerRef}
    >
      <Col className='position-absolute d-lg-block d-none'>
        <Image
          src={BigPuzzle}
          alt='metachain puzzle'
          className='position-absolute big-puzzle  h-auto'
        />
      </Col>
      <Col md={8} xl={6} className='text-center content-footer'>
        <div className='title-div'>
          <Image
            src={FooterLogo}
            alt="Metachain's footer logo encapsulates our mission to collaborate with you in AI, blockchain, and Metaverse technologies for a brighter future"
            title='Metachain Technologies Symbol of Trust'
            id='id_logoFooter'
            width={110}
            height={98}
          />
        </div>
        <p className='text-footer' id='id_spanFooter'>
          Get informative news about the Metaverse trends here!
        </p>
        <Row className='justify-content-center align-items-center'>
          <Col lg={6} xl={6} className='text-center'>
            <ul className='social-ul-footer'>
              {socialMedia.map((socialMedia) => (
                <li key={socialMedia.id}>
                  <a
                    href={socialMedia.link}
                    rel='noreferrer'
                    target='_blank'
                    className='linkedin-team'
                    id={socialMedia.id}
                    aria-label={socialMedia.ariaLabel}
                    title={socialMedia.ariaLabel}
                  >
                    <i>{socialMedia.iconClass}</i>
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <hr className='d-none d-lg-block' />
        <Row className='justify-content-center align-items-center rights'>
          <Col lg={7} xl={7} className='text-center'>
            <p>Metachain Technologies, Inc. © 2023 All Rights Reserved.</p>
          </Col>
        </Row>
      </Col>

      <Col className='position-absolute d-lg-block d-none'>
        <Image
          src={SmallPuzzle}
          alt='metachain puzzle'
          className='position-absolute sm-puccle  h-auto'
        />
      </Col>
    </Row>
  );
}

export default Footer;
