import React, { useRef, useState } from "react";
import schema from "../../configs/schema";
import { Button } from "react-bootstrap";
import { request } from "../../plugins/axios";
import { endpoints } from "../../utils/networks";
import { Spinner } from "react-bootstrap";
import dynamic from "next/dynamic";
const ReCAPTCHA = dynamic(() => import("react-google-recaptcha"), {
  ssr: false,
});
import { isCSR } from "@/utils/helpers";
import { toast } from "react-toastify";

function Form({ onSubmit, categoryId, ...props }) {
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const recaptchaRef = useRef();

  const isClient = isCSR();

  const [formdata, setFormdata] = useState({
    category_id: categoryId,
    first_name: "",
    last_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(false);
  /* Define an empty variable to be used to dump the form after submitting  */
  const initialData = {
    category_id: categoryId,
    first_name: "",
    last_name: "",
    email: "",
    subject: "",
    message: "",
  };

  const submitGetInTouch = (form) => {
    setLoading(true);
    request(
      endpoints.MetaChainApi.SendRequest.method,
      endpoints.MetaChainApi.SendRequest.url,
      formdata,
    )
      .then(({ data }) => {
        onSubmit();
        setFormdata(initialData);
        recaptchaRef.current.reset();
      })
      .catch(({ response }) => {
        if (response?.data?.errors) {
          Object.keys(response?.data?.errors).forEach((_error) => {
            toast.error(response?.data?.errors[_error][0]);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*  this function is used to submit Form */
  const submitHandler = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      toast.error("Please verify you are a human");
      return;
    }

    schema
      .validate(formdata, { abortEarly: false })
      .then(() => {
        submitGetInTouch(formdata);
      })
      .catch((error) => {
        setError(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}),
        );
      });
  };
  /*  this function used to handle value of input to set it to state */
  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setError({
      ...error,
      [event.currentTarget.name]: false,
    });
  };

  return (
    <>
      <form className="form row position-relative" onSubmit={submitHandler}>
        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="text"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.first_name && "is-invalid"
            }`}
            name="first_name"
            placeholder="First Name"
            onChange={handleChange}
            value={formdata.first_name}
            id="id_firstNameGetTouch"
          />
          {error.first_name && (
            <div className="invalid-feedback" id="id_nameErrorGetTouch">
              {error.first_name}
            </div>
          )}
        </div>
        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="text"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.last_name && "is-invalid"
            }`}
            name="last_name"
            placeholder="Last Name"
            onChange={handleChange}
            value={formdata.last_name}
            id="id_lastNameGetTouch"
          />
          {error.last_name && (
            <div className="invalid-feedback" id="id_nameErrorGetTouch">
              {error.last_name}
            </div>
          )}
        </div>
        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="email"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.email && "is-invalid"
            }`}
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={formdata.email}
            id="id_emailGetTouch"
          />
          {error.email && (
            <div className="invalid-feedback" id="id_emailErrorGetTouch">
              {error.email}
            </div>
          )}
        </div>

        <div className="form-group col-12 col-md-6 mb-3">
          <input
            type="subject"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.subject && "is-invalid"
            }`}
            name="subject"
            placeholder="subject"
            onChange={handleChange}
            value={formdata.subject}
            id="id_subjectGetTouch"
          />
          {error.subject && (
            <div className="invalid-feedback" id="id_subjectErrorGetTouch">
              {error.subject}
            </div>
          )}
        </div>

        <div className="form-group col-12 mb-3">
          <textarea
            name="message"
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              error.message && "is-invalid"
            }`}
            rows="10"
            placeholder="Message"
            onChange={handleChange}
            value={formdata.message}
            id="id_messageGetTouch"></textarea>
          {error.message && (
            <div className="invalid-feedback" id="id_msgErrorGetTouch">
              {error.message}
            </div>
          )}
        </div>

        <div className="form-group col-12 col-md-12 mb-3">
          <ReCAPTCHA
            id="recapacha-style"
            size="normal"
            badge="inline"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            onChange={setCaptchaValue}
            grecaptcha={isClient ? window.grecaptcha : null}
            ref={recaptchaRef}
          />
        </div>
        <div className="col-12 col-md-12">
          <Button
            variant="primary"
            className="custom-primary px-10"
            id="MessageSend"
            disabled={loading}
            type="submit">
            Submit
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
              />
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export default Form;
