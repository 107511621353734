import "@/styles/globals.scss";
import type { AppProps } from "next/app";
import Script from "next/script";
import Head from "next/head";
import { ToastContainer } from "react-toastify";
import { toastConfig } from "@/plugins/toaster";
import { Container } from "react-bootstrap";
import Footer from "@/components/Layout/Footer";
import GetTouch from "@/components/GetTouch";
import NewsLetter from "@/components/Blog/NewsLetter";
import CalendlyWidget from "@/components/common/Calendly";
import { CalendlyModalProvider } from "@/contexts/Calendly";

export default function App({ Component, pageProps, router }: AppProps) {
  const is404Page = router.pathname === "/404";
  const isBlogPage = router.pathname.startsWith("/insights");

  return (
    <>
      <Head>
        <link rel='manifest' href='/manifest.json' />
        <meta name='theme-color' content='#15151E' />
        <meta name='apple-mobile-web-app-status-bar' content='black-translucent' />
        <meta name='msapplication-TileColor' content='#ffffff' />

        <link rel='alternate' href='https://mcti.io/' hrefLang='en-us' />
        <link rel='alternate' href='https://mcti.io/' hrefLang='x-default' />

        {/*Start virificators*/}
        <meta name='facebook-domain-verification' content='x4co5j306ep4iiwwz7b877h6q065gf' />
        <meta
          name='google-site-verification'
          content='JWvgPj7smcU44VHUP1R8TmCJuevcpQh0RjM9njsf3JM'
        />
        {/*End virificators*/}

        {/* Start favicon */}
        <link rel='shortcut icon' href='/favicon.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='data-image' href='/meta-data-image.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        {/*End favicon*/}
      </Head>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      {/* Google analytics */}
      <Script id={"1"} strategy='afterInteractive'>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
      page_path: window.location.pathname,
      });
  `}
      </Script>

      {/* start Google Tag Manager (script) */}
      <Script id={"2"} strategy='afterInteractive'>
        {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');
  `}
      </Script>
      {/* end Google Tag Manager (script) */}
      {/* start Twitter conversion tracking */}
      <Script id={"3"} strategy='afterInteractive'>
        {`
      !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      // Insert Twitter Pixel ID and Standard Event data below
      twq('init','o4q6d');
      twq('track','PageView');

  `}
      </Script>
      <CalendlyModalProvider>
        <main>
          {/* start Google Tag Manager (noscript) */}
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`}
              height='0'
              width='0'
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
          <ToastContainer {...toastConfig} /> <Component {...pageProps} />
          {!(is404Page || isBlogPage) ? (
            <>
              <Container fluid className='px-0'>
                <GetTouch />
              </Container>
              <Container fluid>
                <Footer />
              </Container>
            </>
          ) : isBlogPage ? (
            <>
              <Container fluid className='px-0 '>
                <NewsLetter />
              </Container>
              <Container fluid>
                <Footer />
              </Container>
            </>
          ) : null}
        </main>
        <CalendlyWidget />
      </CalendlyModalProvider>
    </>
  );
}
