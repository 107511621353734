const TOKEN = 'YOUR_TOKEN';
export const config = {
  // api's base url
  baseURL: 'https://meta.temaverse.io/api',
  // requeired headers
  headers: {
    'Content-Type': 'application/json',
    Authorization: TOKEN && TOKEN.includes('Bearer') ? TOKEN : `Bearer ${TOKEN}`,
  },
};
