import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { request } from "../../plugins/axios";
import { endpoints } from "../../utils/networks";
import { Spinner } from "react-bootstrap";
import dynamic from "next/dynamic";
const ReCAPTCHA = dynamic(() => import("react-google-recaptcha"), {
  ssr: false,
});
import { isCSR } from "@/utils/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
// import styles from "../DemoModal/style.module.css";
import { useEffect } from "react";

function Form({ onSubmit, categoryId, ...props }) {
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");

  useEffect(() => {
    if (recaptchaValue) {
      setRecaptchaError("");
    }
  }, [recaptchaValue]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
    }),
    onSubmit: (values) => {
      if (!recaptchaValue) {
        setRecaptchaError("Please verify you are not a robot");
        return;
      }
      setLoading(true); // Set loading to true before making the API request
      request(endpoints.MetaChainApi.requestDemo.method, endpoints.MetaChainApi.requestDemo.url, {
        email: values.email,
        category_id: categoryId,
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          onSubmit();
          setLoading(false);
        });
    },
  });

  return (
    <form className='form row position-relative' onSubmit={formik.handleSubmit}>
      <div className='form-group col-12 mb-3'>
        <input
          type='email'
          className={`form-control form-control-solid border-0  ${
            formik.errors.email && "is-invalid"
          }`}
          name='email'
          placeholder='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id='id_emailGetTouch'
        />
        {formik.touched.email && formik.errors.email && (
          <div className={"error-message"}>{formik.errors.email}</div>
        )}
      </div>

      <div className='form-group col-12 col-md-12 mb-3 d-flex flex-wrap gap-2'>
        <div>
          <ReCAPTCHA
            id='recapacha-style'
            size='normal'
            badge='inline'
            name='recaptchaRef'
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            onChange={(value) => {
              setRecaptchaValue(value);
              formik.setFieldValue("recaptchaRef", value); // Set the formik field value
            }}
            grecaptcha={isCSR() ? window.grecaptcha : null}
            ref={recaptchaRef}
          />
          {recaptchaError && <div className={"error-message"}>{recaptchaError}</div>}
        </div>

        <Button
          variant='primary'
          className='custom-primary px-10 my-auto mt-3 mt-lg-auto'
          id='MessageSend'
          disabled={loading}
          type='submit'
        >
          Submit
          {loading && (
            <Spinner
              style={{ marginLeft: ".5rem" }}
              as='span'
              animation='grow'
              size='sm'
              role='status'
            />
          )}
        </Button>
      </div>
    </form>
  );
}

export default Form;
