import { useRouter } from "next/router";
import { useRef } from "react";

export const useModal = () => {
  const router = useRouter();

  const getInitialPath = () => {
    const asPath = router.asPath;
    const queryIndex = asPath.indexOf("?");
    if (queryIndex !== -1) {
      return asPath.substring(0, queryIndex);
    }
    return asPath;
  };

  const initialPath = useRef(getInitialPath());

  const openModal = (modal, options = {}) => {
    const query = router.query;

    return router.push(
      {
        pathname: initialPath.current,
        query: {
          ...query,
          ...options,
          step: modal,
        },
      },
      undefined,
      { shallow: true },
    );
  };
  const closeCurrentModal = async () => {
    const query = router.query;
    delete query.step;
    await router.replace(
      {
        pathname: initialPath.current,
        query,
      },
      undefined,
      { shallow: true },
    );
  };
  const currentModal = router.query.step;
  return { openModal, closeCurrentModal, currentModal };
};
