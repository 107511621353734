import React, { FC } from "react";
import dynamic from "next/dynamic";
import { useCalendlyModal } from "@/contexts/Calendly";
const PopupModal = dynamic(() => import("react-calendly").then((mod) => mod.PopupModal), {
  ssr: false,
});

const CalendlyWidget: FC = () => {
  const { toggleModal, isCalendlyOpen } = useCalendlyModal();
  return (
    <PopupModal
      url='https://calendly.com/client_meeting/metachain'
      /* @ts-ignore */
      rootElement={
        typeof document !== "undefined" && (document.getElementById("__next") as HTMLElement)
      }
      onModalClose={toggleModal}
      open={isCalendlyOpen}
      pageSettings={{
        backgroundColor: "#0b1d30",
        textColor: "#ffffff",
        hideLandingPageDetails: true,
        hideGdprBanner: true,
      }}
    />
  );
};
export default CalendlyWidget;
