export const generateStars = (
  starContainer: HTMLElement,
  numStars: number,
  customHeight?: number,
  animateOnAppear?: boolean
) => {
  const stars: Array<HTMLElement> = [];

  for (let i = 0; i < numStars; i++) {
    const star = document.createElement("div");
    star.className = "star";
    star.style.left = `${Math.random() * 100}vw`;
    star.style.top = `${Math.random() * (customHeight ?? 50)}vh`;
    star.style.animationDuration = `${Math.random() * 3 + 2}s`; // between 2 to 5 seconds
    star.style.animationDelay = `${Math.random() * 2}s`;
    star.style.animationTimingFunction = `cubic-bezier(${Math.random()}, ${Math.random()}, ${Math.random()}, ${Math.random()})`;
    if (numStars > 70) {
      star.classList.add("d-md-block");
      star.classList.add("d-none");
    }

    if (animateOnAppear) {
      star.classList.add("animateOnAppear");
    }

    stars.push(star);
    starContainer?.appendChild(star);
  }

  return () => {
    stars.forEach((star) => starContainer?.removeChild(star));
  };
};
