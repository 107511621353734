import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "./Form";
import Image from "next/image";

function NewsLetter() {
  // const { openModal, closeCurrentModal, currentModal } = useModal();
  const [isSuccess, setIsSuccess] = React.useState(false);

  return (
    <Row className='justify-content-center align-items-center my-20'>
      <Col md={10} className='mx-auto'>
        <Row className='align-items-center '>
          <Col md={10} className='mx-auto  newsletter'>
            <Row className='align-items-center '>
              <Col className='televerse-world-text' lg={6}>
                <h2 className='bold mb-3 '>
                  Subscribe <br />
                  to our newsletter
                </h2>

                <p className='text-white pe-1'>
                  Stay ahead of the curve with exclusive insights and updates - subscribe to our
                  newsletter now!
                </p>
              </Col>
              <Col lg={6}>
                <Row className='justify-content-center align-items-center contact-form-bg newsletter-box'>
                  <Col lg={12} xl={12}>
                    <Form
                      categoryId='14'
                      onSubmit={() => {
                        // openModal("submit");
                        setIsSuccess(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NewsLetter;
