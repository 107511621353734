import * as yup from "yup";
/*  use Yup schema as builder for value parsing and validation
 * ref :  https://www.npmjs.com/package/yup
*/
export default yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

