import { FaFacebookF, FaLinkedinIn, FaRedditAlien, FaInstagram } from "react-icons/fa";
import { PiMediumLogoFill } from "react-icons/pi";
import { RiTwitterXFill } from "react-icons/ri";

export const socialMediaConfig = [
  {
    id: "facebook",
    link: "https://www.facebook.com/MetachainTechnologies",
    iconClass: <FaFacebookF />,
    ariaLabel: "Facebook",
  },
  {
    id: "twitter",
    link: "https://twitter.com/MetachainInc",
    iconClass: <RiTwitterXFill />,
    ariaLabel: "Twitter",
  },
  {
    id: "linkedin",
    link: "https://www.linkedin.com/company/metachain-technologies-inc",
    iconClass: <FaLinkedinIn />,
    ariaLabel: "Linkedin",
  },
  {
    id: "medium",
    link: "https://medium.com/@metachainsocialmedia",
    iconClass: <PiMediumLogoFill />,
    ariaLabel: "Medium",
  },
  {
    id: "Reddit",
    link: "https://www.reddit.com/user/MetachainTech",
    iconClass: <FaRedditAlien />,
    ariaLabel: "Reddit",
  },
  {
    id: "instagram",
    link: "https://www.instagram.com/metachain_tech/",
    iconClass: <FaInstagram />,
    ariaLabel: "instagram",
  },
];
